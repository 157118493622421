.sign-in-wrapper{
           /* Change the white to any color */
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active{
    -webkit-box-shadow: 0 0 0 30px #91BC3D inset !important;
}
/*Change text in autofill textbox*/
input:-webkit-autofill{
    -webkit-text-fill-color: #fff !important;
}
    min-height: calc(100vh - 120px) !important;
    width: 100%;
    // background-image: url(../../../../src/images/bg.png);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &::before{
        height: 100%;
        width: 100%;
        content: "";
        position: absolute;
        // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(14,111,57,0.40379901960784315) 0%, rgba(144,187,62,1) 100%);
        opacity: 0.6;
    }
    .register-form{
        width: 100%;
        height: 100%;
        padding: 30px 60px;
        min-height: calc(100vh - 120px) !important;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        @media screen and (max-width:676px) {
            padding: 0px !important;
        }
        .custom-form-input-wrap{
            Input{
                border-bottom: 1px solid #fff !important;
            }
            label{
                color: #fff !important;
                font-size: 1.5rem !important;
            }
            
        }
        .white-clr-link{
            color: #fff;
            a{
                color: #fff;
                text-decoration: underline !important;
            }
        }
        .right-box{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .brand-logo{
                width: 400px;
                @media screen and (max-width:1400px) {
                    width: 300px;
                 }
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .logo-details{
                font-size: 18px;
                color: #878787;
                text-align: center;
                width: 540px;
            }
        }
    }
    .sign-in-form{
        @media screen and (max-width:1740px) {
            padding: 0 70px;
        }
        @media screen and (max-width:1400px) {
            padding: 0 50px;
        }
        @media screen and (max-width:676px) {
            padding: 15px !important;
        }
        .add-lead-table-wrap{
            border-radius: 23px;
            max-height: 800px;
            height: 100%;
            padding: 50px;
            padding-bottom: 20px !important;
            @media screen and (max-width:1400px) {
                padding: 30px;
             }
            .main-heading{
                font-size: 2.5rem;
                text-align: center;
                font-weight: 600;
                color: #fff;
                @media screen and (max-width:1740px) {
                   font-size: 36px;
                }
            }
            .custom-form-input-wrap{
                Input {
                    font-size: 1rem;
                    font-weight: bold;
                    &::placeholder {
                      font-size: 1rem;
                      font-weight:bold;
                    }
                  }
                .country-select{
                    width: 100% !important;   
                    input{
                        background-color: transparent;
    
                    }
            }
            }
            
        }
        .black-clr-link{
            @media screen and (max-width:676px){
                font-size: 0.9rem;
            }
        }
    }
}
.sign-logo{
    width: 150px !important;
    margin-bottom: 1rem !important;
  }
  .bold-text{
    font-family: 'calibri-bold' !important;
    font-size: 1.2rem;
  }
