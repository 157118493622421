.main-container {
    min-height: 70vh;
    width: 100%;
    display :flex;
    justify-content: center;
    align-items: center;
}
.content-container {
    width: 650px;
    height: 650px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #91BC3D30;
    padding: 10px;
    text-align: center;
}