.search-bar-wrap{
    position: relative;
    width: 100%;
    .search-icon{
        position: absolute;
        right: 13px;
        top: 2px;
        font-weight: 100;
        font-size: 20px;
        @media screen and (max-width:1640px) {
            font-size: 18px;
          }
          @media screen and (max-width:1400px) {
            font-size: 18px;
          }
    }
    .select-icon{
        position: absolute;
        left: 3px;
        padding: 0 5px;
        top: 0px;
        font-weight: 100;
        font-size: 23px;
        border-right: 1px solid #DFDFDF;
        @media screen and (max-width:1640px) {
            font-size: 21px;
          }
          @media screen and (max-width:1400px) {
            font-size: 21px;
          }
    }
    .custom-search-bar{
        border: 1px solid #DFDFDF;
        border-radius: 9px;
        width: 100%;
        font-size: 15px;
        padding: 6px 45px;
        background-color: #EFEFEF;
        &:focus-visible{
            outline: 1px solid #DFDFDF !important;
        }
        @media screen and (max-width:1640px) {
            // font-size: 13px;
          }
    }
}