.main-wrapper {
  width: 100%;
  max-width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  max-height: auto !important;
  h2 {
    color: #00722d;
  }
  h4 {
    font-family: 'caibri' !important;
  }
  .subscription-box {
    width: 480px;
    border: 1px solid #91bc3d;
    border-radius: 32px;
    overflow: hidden;
    margin-bottom: 15rem;
    .plan-price {
      height: 150px;
      background-color: #91bc3d;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      color: #fff;
      h1 {
        color: #fff;
        span {
          font-size: 16px;
        }
      }
      .subscription-btn {
        position: absolute;
        bottom: -25px;
        left: 50%;
        transform: translateX(-50%);
        z-index: 2;
        font-size: 20px;
        height: unset;
        &:hover {
          background: #fff;
        }
      }
    }
    .subscription-policy {
      min-height: 280px;
      background-color: #fff;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      .check-icon {
        margin-right: 20px;
        font-size: 14px;
      }
    }
  }
}
.subscription-list {
  list-style-type: disc;
  font-size: 1rem;
  li {
    font-family: 'caibri' !important;
    // white-space: nowrap;
  }
}

.hybrid-img {
  max-height: 700px;
}
// Commented code for Hybrid plan subscription page when dynamic values required
// .hybrid-card {
//   background-color: #91bc3d;
//   border-radius: 32px;
//   padding: 16px 8px 56px;

//   .hybrid-card-header {
//     padding: 16px 32px;

//     .title {
//       font-size: 28px;
//       font-weight: 900;
//     }
//   }

//   .hybrid-card-body {
//     background-color: #fff;
//     padding: 12px 24px;
//     min-height: 400px;
//     height: 100%;

//     .initial-price {
//       color: #91bc3d;
//       font-size: 32px;
//       font-weight: 900;
//     }

//     .price-description {
//       padding: 16px;
//       font-size: 20px;
//       font-weight: 600;
//     }
//   }
// }
// .hybrid-benefits-list {
//   ul {
//     list-style: none;
//     padding-left: 60px;

//     li {
//       position: relative;
//       font-size: 20px;
//       font-weight: 600;
//       margin-bottom: 10px;

//       &::before {
//         content: "";
//         position: absolute;
//         left: -50px;
//         top: 5px;
//         height: 20px;
//         width: 20px;
//         border-radius: 50%;
//         border: 3px solid #00722d;
//       }

//       .red {
//         color: red !important;
//       }

//       .bold {
//         font-weight: 900;
//       }
//     }
//   }
// }

@media screen and (max-width: 768px) {
  .image-wrapper {
    width: 80%;
    margin: 0 auto;
    text-align: center;
    img {
      height: 30vh;
      object-fit: contain;
    }
  }
  .main-wrapper {
    .text-align {
      text-align: center;
    }
  }
}