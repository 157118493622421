.filter-btn{
    background-color: #00722D;
    border-radius: 5px;
    color: #fff;
    padding: 4px 8px;
    font-size: 13px;
    margin-right: 10px;
}
.custom-delete-icon{
    font-size: 1.8rem; color: red; cursor: pointer
}
.archive-wrapper{
    .ant-table-row{
      cursor: pointer !important;
    }
}
