.sign-in-wrapper{
    min-height: 100vh;
    width: 100%;
    // background-image: url(../../../../src/images/bg.png);
    background-repeat: no-repeat;
    background-position: center;
    position: relative;
    &::before{
        height: 100%;
        width: 100%;
        content: "";
        position: absolute;
        // background: linear-gradient(90deg, rgba(2,0,36,1) 0%, rgba(14,111,57,0.40379901960784315) 0%, rgba(144,187,62,1) 100%);
        opacity: 0.6;
    }
    .register-form{
        width: 100%;
        height: 100%;
        padding: 30px 60px;
        min-height: 100vh;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .custom-form-input-wrap{
            label{
                color: #fff !important;
            }
            
        }
        .white-clr-link{
            color: #fff;
            a{
                color: #fff;
                text-decoration: underline !important;
            }
        }
        .right-box{
            height: 100%;
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            .brand-logo{
                width: 400px;
                @media screen and (max-width:1400px) {
                    width: 300px;
                 }
                img{
                    height: 100%;
                    width: 100%;
                }
            }
            .logo-details{
                font-size: 18px;
                color: #878787;
                text-align: center;
                width: 540px;
            }
        }
    }
    .forgot-form{
        @media screen and (max-width:1740px) {
            padding: 0 70px;
        }
        @media screen and (max-width:1400px) {
            padding: 0 50px;
        }
        .add-lead-table-wrap{
            border-radius: 23px;
            max-height: 800px;
            min-height: 450px;
            height: 100%;
            padding: 50px;
            @media screen and (max-width:1400px) {
                padding: 30px;
             }
            .main-heading{
                font-size: 2.5rem;
                text-align: center;
                font-weight: 600;
                color: #fff;
                @media screen and (max-width:1740px) {
                   font-size: 36px;
                }
            }
            .custom-form-input-wrap{
    
                .country-select{
                    width: 100% !important;
                    input{
                        background-color: transparent;
    
                    }
            }
            }
            
        }
    }

}
.custom-msg-forget {
    margin-top: 53vh;
    text-align: left;
    margin-left: 10%;
  }
