@import '/src/app/components/scss/buttons';
@import '/src/app/components/scss/SearchBar';
@import '/src/app/components/scss/table';
@import '/src/app/components/scss/input';
@import '/src/app/components/scss/fonts';
@import '/src/app/components/scss/reset';
@import '/src/app/components/scss/pagination';

.ant-table-column-sorter {
  display: none;
}
.ant-table-column-sorter-up.active,
.ant-table-column-sorter-down.active {
  color: #00722d !important;
}
.ant-table-row {
  .ant-tag {
    border-radius: 8px;
    padding: 7px 12px;
    color: #fff;
    font-size: 0.85rem;
    min-width: 125px;
    text-align: center;
    margin-right: 0px !important;
    font-weight: bold;

    @media screen and (max-width: 1640px) {
      font-size: 10px;
      padding: 4px 8px;
      min-width: 85px;
    }

    &.ant-tag-geekblue {
      background-color: #369df2;
    }

    &.ant-tag-green {
      background-color: #00722d;
    }

    &.ant-tag-yellow {
      background-color: #ffdc3d;
      color: #1f1f25 !important;
    }

    &.ant-tag-red {
      background-color: #f21400;
    }
  }
}

.dropdown-option-geekblue {
  background-color: #369df21b;
}

.dropdown-option-green {
  background-color: #00722e20;
}

.dropdown-option-yellow {
  background-color: #ffdb3d12;
  color: #1f1f25 !important;
}

.dropdown-option-red {
  background-color: #f214000f;
}

.ant-layout-content {
  width: 100% !important;

  .payment-summary-wrap {
    border: 1px solid #00722d;
    border-radius: 10px;
    padding: 20px;

    .detail-box {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin: 10px 0;

      .title {
        color: #878787;
      }

      .amount {
        color: #878787;
      }

      &.total {
        margin-top: 50px;

        .title {
          color: #1f1f25;
        }

        .amount {
          color: #1f1f25;
        }
      }
    }
  }
}
.submit-height {
  height: 112px !important;
}

.ant-spin-nested-loading > div > .ant-spin {
  min-height: 100vh !important;
  font-size: 20px;
  color: black;
}
.ant-table-column-title {
  white-space: nowrap !important;
}
.custom-tag {
  min-width: 105px !important;
  &:hover{
    // background-color: #91bc3d !important;
    color: #fff;
    // border: 1px solid #91bc3d;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transform: translateY(-5px);
    transition: color 0.5s;
    transition: transform 0.7s;
}
&:focus{
    // background-color: #91bc3d;
    color: #fff;
    // border: 1px solid #91bc3d;
    box-shadow: 0 0.5em 0.5em -0.4em #707070;
    transition: color 0.5s;
    transition: transform 0.7s;
}
}
.react-tel-input .form-control {
  width: 100% !important;
  border-radius: 0px;
}
.contact_input .form-control {
  padding: 5px !important;
}
.flag-dropdown {
  display: none !important;
}
.custom-msg {
  margin-top: 60vh;
  text-align: left;
  margin-left: 180px;
}
.btn-filter {
  border: 1px solid #00722d;
  opacity: 0.8 !important;
  font-size: 1rem !important;
  width: 150px;
  height: 38px;
  border-radius: 5px;
}
.custom-btn-green {
  color: #fff !important;
  background-color: #00722d !important;
}
.custom-card {
  border: 1px solid #00722d;
  border-radius: 10px;
  width: 19%;
  padding: 1rem;
  height: 75px;
  background-color: #e7f3ce;
  div {
    white-space: nowrap;
  }
  h2 {
    font-size: 1.1rem;
  }
}
.custom-not-data {
  height: 70vh;
  width: 90%;
  opacity: 0.6;
}
.custom-tooltip {
  border: 1px solid #00722d;
  border-radius: 10px;
  background-color: #e7f3ce;
  padding: 1rem;
}
.recharts-tooltip-wrapper:hover {
  background-color: red !important;
}

.ant-layout-content {
  margin: 24px 16px 0px;
  overflow: initial;
  padding: 0px !important;
  min-height: 0px !important;
}
a {
  font-family: 'calibri' !important;
}
.ant-menu-title-content a {
  font-family: 'calibri-bold' !important;
}
.ant-menu-item-selected {
  background-color: #00722d !important;
}
body {
  font-family: 'calibri' !important ;
}
.recharts-cartesian-axis-tick {
  font-family: 'calibri-bold';
}
.main-wrapper {
  height: 82vh !important;
}
.custom-error {
  font-size: 0.95rem;
  color: red;
}
.custom-success {
  font-size: 0.95rem;
  padding: 10px;
  color: #00722d;
}
.react-tel-input .form-control:disabled {
  opacity: 1;
  color: rgba(0, 0, 0, 0.25);
  background-color: #f5f5f5;
  border-color: #d9d9d9;
  box-shadow: none;
  cursor: not-allowed;
  opacity: 1;
}
.search-bar-wrap .custom-search-bar {
  padding: 6px 11px !important;
}
.ant-tooltip-inner {
  color: #111;
  background-color: #f7f7f7;
  padding: 13px;
  font-size: 15px;
  border: 1px solid #00722d;
  border-radius: 10px;
}

.ant-tooltip-placement-bottom .ant-tooltip-arrow,
.ant-tooltip-placement-bottomLeft .ant-tooltip-arrow,
.ant-tooltip-placement-bottomRight .ant-tooltip-arrow {
  border-bottom-color: green;
}
.subscription-spinner {
  margin-top: -50%;
}
.scrollableDiv {
  height: 75vh;
  overflow: auto;
}
.scrollableDivArchive {
  height: 75vh !important;
  overflow: auto;
}
.ant-table-thead {
  position: sticky;
  top: 0;
  height: 5vh;
  background-color: red;
  z-index: 1;
  background-color: #fff;
}
.landing-msg {
  p {
    font-family: 'calibri-bold' !important;
    @media only screen and (min-width: 1600px) {
      font-size: 1.4rem;
    }
  }
  .heading-paragraph {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .last-paragraph {
    margin-top: -1rem;
  }
}
.landing-wrapper {
  margin-top: 5rem;
}
.react-tel-input {
  input::placeholder {
    opacity: 0.5 !important;
  }
}
.ant-table-filter-dropdown {
  width: 200px;
}
.ant-table-filter-dropdown-btns .ant-btn {
  width: 40%;
}
.ant-empty-description {
  font-size: 1.5rem !important;
  font-family: 'calibri-bold' !important;
}
.lead-detail-submit {
  width: 95%;
  margin-left: 20px;
}
.lead-detail-delete {
  width: 95%;
}
.date-range-text {
  border-radius: 12px;
  font-size: 1rem;
  padding-left: 1rem;
  padding-right: 1rem;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #dee2e6;
  display: flex;
  align-items: center;
  width: 300px;
  text-align: center;
  justify-content: center;
  cursor: pointer;
}
.date-range-wrapper {
  display: flex;
  align-items: center;
}
.bold-font {
  font-weight: bold !important;
}
.date-range-picker {
  margin-left: 10px;
  margin-right: 10px;
}
.rdrDefinedRangesWrapper {
  width: 150px;
}
.chart-height {
  height: 65vh;
}
.chart-label {
  text-anchor: middle;
  font-size: 1rem;
  font-weight: bold;
}
.ant-select-selection--multiple {
  white-space: nowrap !important;
  height: 30px !important;
  overflow: auto !important;
}
.ant-select-item-option-disabled {
  color: rgba(0, 0, 0, 0.25) !important;
}
.date-range-picker {
  flex-direction: column;
  .picker-btn {
    border-radius: 10px !important;
    font-weight: bold;
    font-size: 1rem;
    max-height: 40px;
    height: 40px;
    &:hover {
      background-color: rgb(44 161 90);
      color: #fff;
    }
  }
}
.rdrStaticRangeLabel {
  font-size: 1rem;
  white-space: nowrap;
}
.rdrMonth {
  width: 100% !important;
}
.rdrCalendarWrapper {
  font-size: 1rem;
  width: 100% !important;
}
.rdrDefinedRangesWrapper .rdrStaticRangeSelected {
  color: currentColor;
  font-weight: 600;
  background: rgb(44 161 90);
  color: #fff !important;
}
.rdrStaticRange:hover .rdrStaticRangeLabel,
.rdrStaticRange:focus .rdrStaticRangeLabel {
  background: rgb(44 161 90);
  color: #fff !important;
}
.export-range {
  font-size: 1rem;
}
.custom-checkboxes {
  .ant-checkbox-checked .ant-checkbox-inner {
    background-color: rgb(44 161 90);
    border-color: rgb(44 161 90);
  }
}
.custom-list-checkbox {
  background-color: #f1f1f1 !important ;
  height: 50px;
  align-items: center;
}
.error-msg {
  color: red;
  font-size: 1rem;
}
.row-height {
  margin-top: -6rem;
}
.ant-checkbox-group {
  white-space: nowrap;
}
.brand-logo-responsive {
  @media screen and (max-width: 767px) {
    width: 300px;
    height: auto;
    margin: 0 auto;
  }
}
