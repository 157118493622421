.lead-table-header{
    padding: 12px 25px;
    width: 100%;
    border-radius: 4px 4px 0 0;
    color:#fff;
    font-size: 20px;
    display: flex;
    
    .table-header-icon{
        font-size: 20px;
        margin-right: 10px;
        display: flex;
        align-items: center;
    }
}
.add-lead-table-wrap{
    padding: 50px;
    background-color: #91BC3D;
    box-shadow: 0px 0px 10px #0000000D;
    min-height: 300px;
}
.ant-form-item {
    margin: 0px !important;
}