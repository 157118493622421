.header{
    background: #fff;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    line-height: 1;
    height: 100px;
    border-bottom: 7px solid #91BC3D;
    position: fixed;
    top: 0;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    .logo{
        cursor: pointer;
        height: 90px;
        margin-left: 60px;
        @media (max-width:676px) {
            margin-left: 20px;
        }
        img{
            max-height: 100%;
        }
    } 
    .nav-wrapper{
        height: 100%;
        .nav{
            margin-top: 18px;
            .nav-item{
                margin-right: 20px;
                display: flex;
                align-items: center;
                .nav-link{
                    color: #1F1F25;
                    cursor: pointer !important;
                }
                .user-dropdown{
                    position: relative;
                    margin-right: 40px;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    cursor: pointer;
                    @media  (max-width:676px) {
                        margin-right: 10px;
                    }
                    .img-cover{
                        width: 42px;
                        height: 42px;
                        color: #fff !important; 
                        font-size: 28px;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                        border-radius: 50%;
                        background-color:#00722D 
    
                    }
                    
                    span{
                        color: #9EA09F;
                        margin-left: 5px;
                        font-size: 24px;
                    }
                    &:hover{
                        .user-dropdown-wrapper{
                            display: block;
                        }
                    }
                    .user-dropdown-wrapper{
                        display: none;
                        transition: all 0.3s;
                        position: absolute;
                        top: 40px;
                        right: 0;
                        min-width: 250px;
                        width: 100%;
                        border-radius: 20px !important;
                       box-shadow: 1px 1px 13px #00000059;
                        background-color: #fff;
                        padding: 10px;
                        .user-dropdown-list{
                            padding: 10px 0;
                            cursor: default;
                            li{
                                padding: 10px 10px;
                                transition: all 0.3;
                                white-space: nowrap;
                                position: relative;
                                &::before{
                                    display: none;
                                    content: '';
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    z-index: 1;
                                    transform: translateY(-50%);
                                    width: 4px;
                                    height: 100%;
                                    background-color: #00722D;
                                }
                                a{
                                    color: #1F1F25;
                                }
                                &:hover{
                                    background-color: #00722e2f;
                                  &::before{
                                      display: block;
                                  }
                                }
                            }

                        }
                        .button-wrapper{
                            display: flex;
                            justify-content: center;
                            padding-top: 10px;
                            .sign-out-btn{
                                border: 0;
                                font-size: 18px;
                                border-radius: 8px;
                                padding: 0 20px;
                                span{
                                    font-size: 18px;
                                    color: #1F1F25;
                                }
                            }
                        }
                        
                    }
                }
            }
        }
        &::before{
            content: '';
            position: absolute;
            right: 0;
            bottom: -7px;
            width: 65%;
            height: 23px;
            border-top: 0px solid transparent;
            border-left: 70px solid transparent;
            border-bottom: 23px solid #00722D;
                }
        
    }
    
    .dropdown {
        position: relative;
        display: inline-block;
      }
      
      .dropdown-content {
        display: none;
        position: absolute;
        background-color: #f9f9f9;
        min-width: 160px;
        box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
        padding: 12px 16px;
        z-index: 1;
      }
      
      .dropdown:hover .dropdown-content {
        display: block;
      }
}
.ant-avatar-string{
    color:#fff !important;
    text-align: center;
    background-color:#00722D !important; 
    transform: scale(1) translateX(-80%) !important
}
.ant-avatar-string-user.ant-avatar-string{
    
        color:#fff !important;
        text-align: center;
        background-color:#00722D !important; 
        transform: scale(1) translateX(-68%) !important;
        background:#00722D !important
    
    
}
.ant-avatar{
    background: #00722D !important;
}
.ant-table-column-title{
    z-index: 0 !important;
} 
.custom-li{
    cursor: pointer !important;
    font-size: 1rem !important;
}
.custom-li-first{
   border-bottom:  1px solid #9ea09f9d;
   cursor: pointer !important;
   font-size: 1rem !important;
   pointer-events: none;
}

@media screen and (max-width: 676px) {
    .header-nav-links-wrapper{
        display: none !important;
    }
}

@media screen and (min-width: 676px) {
    .hider{
        display: none !important;
    }
}