.notes-wrapper{
    margin-top: 50px;
    border-top:  1px solid #DFDFDF;
    padding: 50px;
    .head-bar{
        display: flex;
        justify-content: space-between;
        h4{
            color: #00722D;
        }
    }
}
.notes-content{
    .heading{
        h5{
            color: #00722D;
        }
    }
}
.lead-title{
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    margin-left: 50px;
    h2{
        font-family: 'calibri-bold';
        margin-bottom: 0;
        margin-right: 10px;
        font-size: 30px;
    }
    span{
        a{
            color: #00722D;
        font-family: 'calibri-bold';
        }
    }
}