.ant-table-wrapper {
  .ant-table {
    z-index: 0;

    .ant-table-thead {
      th {
        border-bottom: 0;
        background-color: transparent;
        font-size: 20px;
        padding: 13px 2px;
        font-family: 'calibri-bold';
        &::before{
          height: 0 !important;
        }
        @media screen and (max-width:1740px) {
          font-size: 16px;
          padding:13px 6px;
        }
        @media screen and (max-width:1400px) {
          font-size:14px;
          padding:13px 4px;
        }
      }
    }

    .ant-table-tbody {

      td {
        font-size: 18px;
        border-bottom: 0;
        padding: 13px 2px;
        font-family: 'calibri';
        @media screen and (max-width:1740px) {
          font-size: 14px;
          padding:13px 6px;
        }
        @media screen and (max-width:1400px) {
          font-size: 13px;
          padding:13px 4px;
        }
      }
    }
  }
}
td.ant-table-column-sort {
  background: transparent !important;
}