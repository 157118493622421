.subscription-history {
  .title {
    font-size: 18px;
    max-width: 650px;
    font-family: 'calibri-bold' !important;
  }
  .description {
    font-family: 'calibri';
  }
  .subscription-action-btn {
    text-decoration: underline;
    cursor: pointer;
    a {
      color: #00722d !important;
    }
  }
}
.payment-tupe-wrap {
  .title {
    font-size: 18px;
    font-family: 'calibri-bold' !important;
  }
  .card-type {
    padding: 8px 6px;
    border: 1px solid #dfdfdf;
    border-radius: 8px;
    background-color: #efefef;
    p {
      margin: 0;
      color: #2d2d2d;
      font-weight: 300;
    }
  }
}
.ant-table-row {
  cursor: default !important;
}
.export-spinner {
  margin-top: -40% !important;
}
.renew-date-picker {
  .ant-picker {
    border: none;
    padding-top: 0px;
    height: 29px;
  }
  .ant-picker-focused {
    border: none;
  }
  .ant-picker-input > input {
    font-size: 18px;
    font-family: 'calibri';
    width: 100px;
    pointer-events: none;
  }
  .ant-picker-input {
    cursor: pointer;
    .ant-picker-suffix {
      width: 20px;
      height: 20px;
      content: url(../../../../src/images/edit-icon.png);
      background-repeat: no-repeat;
    }
    .ant-picker-clear {
      display: none;
    }
  }
}
