.ant-modal{
    .ant-modal-content{
        height: 100%;
        border-radius: 10px !important;
        .ant-modal-close{
            display: none;
        }
        .ant-modal-body {
    font-size: 1.5rem;
    @media (max-width:676px) {
        height: auto !important;
    }
        }
        .ant-modal-header{
            border-radius: 10px 10px 0 0;
            background-color: #00722D;
            .ant-modal-title{
                color: #fff;
                font-size: 1.5rem;
                font-family: 'calibri-bold';
            }
        }
    }
}